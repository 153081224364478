@charset "UTF-8";
/**
 * Grid Variables - CH
 */
/**
 * Custom SASS Mixins - CH
 */
/**
 * Grid Variables - CH
 */
.full-screen-background {
  position: relative;
  box-sizing: border-box;
  text-align: center;
  background: #FFFFFF;
}

.full-screen-background .wrapper {
  padding-bottom: 30px;
}

.full-screen-background .slides {
  position: relative;
}

.full-screen-background .slides:after {
  content: "";
  display: block;
  clear: both;
}

.full-screen-background .nav {
  margin-top: 10px;
}

.full-screen-background .nav .nav-items {
  display: inline-block;
  position: relative;
  height: 32px;
  min-width: 20px;
  margin: 0 15px;
  vertical-align: middle;
  cursor: pointer;
}

.full-screen-background .nav .nav-items:before, .full-screen-background .nav .nav-items:after {
  background-image: url(../Images/player.png);
  content: '';
  display: block;
  position: absolute;
  top: 0;
  bottom: 0;
  width: 9px;
}

.full-screen-background .nav .nav-items:before {
  left: -9px;
  background-position: -154px -152px;
}

.full-screen-background .nav .nav-items:after {
  right: -9px;
  background-position: -225px -152px;
}

.full-screen-background .nav .nav-items .sxa-bullets, .full-screen-background .nav .nav-items .sxa-numbers {
  position: relative;
  height: 32px;
  float: left;
  width: 20px;
}

.full-screen-background .nav .nav-items .sxa-bullets:before {
  background-image: url(../Images/player.png);
  background-position: 0 -152px;
  left: -10px;
  content: '';
  width: 20px;
  height: 32px;
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.full-screen-background .nav .nav-items .sxa-bullets.active:before {
  background-position: -75px -152px;
}

.full-screen-background .nav .nav-items .sxa-numbers {
  padding-top: 3px;
  /* IE9 SVG, needs conditional override of 'filter' to 'none' */
  background: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/Pgo8c3ZnIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgd2lkdGg9IjEwMCUiIGhlaWdodD0iMTAwJSIgdmlld0JveD0iMCAwIDEgMSIgcHJlc2VydmVBc3BlY3RSYXRpbz0ibm9uZSI+CiAgPGxpbmVhckdyYWRpZW50IGlkPSJncmFkLXVjZ2ctZ2VuZXJhdGVkIiBncmFkaWVudFVuaXRzPSJ1c2VyU3BhY2VPblVzZSIgeDE9IjAlIiB5MT0iMCUiIHgyPSIwJSIgeTI9IjEwMCUiPgogICAgPHN0b3Agb2Zmc2V0PSIwJSIgc3RvcC1jb2xvcj0iI2VlZWVlZSIgc3RvcC1vcGFjaXR5PSIxIi8+CiAgICA8c3RvcCBvZmZzZXQ9IjMlIiBzdG9wLWNvbG9yPSIjZWZlZmVmIiBzdG9wLW9wYWNpdHk9IjEiLz4KICAgIDxzdG9wIG9mZnNldD0iMTYlIiBzdG9wLWNvbG9yPSIjZmJmYmZiIiBzdG9wLW9wYWNpdHk9IjEiLz4KICAgIDxzdG9wIG9mZnNldD0iMjIlIiBzdG9wLWNvbG9yPSIjZmVmZWZlIiBzdG9wLW9wYWNpdHk9IjEiLz4KICAgIDxzdG9wIG9mZnNldD0iMzQlIiBzdG9wLWNvbG9yPSIjZmZmZmZmIiBzdG9wLW9wYWNpdHk9IjEiLz4KICAgIDxzdG9wIG9mZnNldD0iNDElIiBzdG9wLWNvbG9yPSIjZmRmZGZkIiBzdG9wLW9wYWNpdHk9IjEiLz4KICAgIDxzdG9wIG9mZnNldD0iNTAlIiBzdG9wLWNvbG9yPSIjZjdmN2Y3IiBzdG9wLW9wYWNpdHk9IjEiLz4KICAgIDxzdG9wIG9mZnNldD0iNjYlIiBzdG9wLWNvbG9yPSIjZThlOGU4IiBzdG9wLW9wYWNpdHk9IjEiLz4KICAgIDxzdG9wIG9mZnNldD0iNzglIiBzdG9wLWNvbG9yPSIjZDVkNWQ1IiBzdG9wLW9wYWNpdHk9IjEiLz4KICAgIDxzdG9wIG9mZnNldD0iODQlIiBzdG9wLWNvbG9yPSIjY2VjZWNlIiBzdG9wLW9wYWNpdHk9IjEiLz4KICAgIDxzdG9wIG9mZnNldD0iODglIiBzdG9wLWNvbG9yPSIjMGQwZjExIiBzdG9wLW9wYWNpdHk9IjAuNDQiLz4KICAgIDxzdG9wIG9mZnNldD0iOTQlIiBzdG9wLWNvbG9yPSIjMGQwZjExIiBzdG9wLW9wYWNpdHk9IjAuMyIvPgogICAgPHN0b3Agb2Zmc2V0PSIxMDAlIiBzdG9wLWNvbG9yPSIjMGQwZjExIiBzdG9wLW9wYWNpdHk9IjAuMTIiLz4KICA8L2xpbmVhckdyYWRpZW50PgogIDxyZWN0IHg9IjAiIHk9IjAiIHdpZHRoPSIxIiBoZWlnaHQ9IjEiIGZpbGw9InVybCgjZ3JhZC11Y2dnLWdlbmVyYXRlZCkiIC8+Cjwvc3ZnPg==);
  background: -moz-linear-gradient(top, #eeeeee 0%, #efefef 3%, #fbfbfb 16%, #fefefe 22%, white 34%, #fdfdfd 41%, #f7f7f7 50%, #e8e8e8 66%, #d5d5d5 78%, #cecece 84%, rgba(13, 15, 17, 0.44) 88%, rgba(13, 15, 17, 0.3) 94%, rgba(13, 15, 17, 0.12) 100%);
  /* FF3.6-15 */
  background: -webkit-linear-gradient(top, #eeeeee 0%, #efefef 3%, #fbfbfb 16%, #fefefe 22%, white 34%, #fdfdfd 41%, #f7f7f7 50%, #e8e8e8 66%, #d5d5d5 78%, #cecece 84%, rgba(13, 15, 17, 0.44) 88%, rgba(13, 15, 17, 0.3) 94%, rgba(13, 15, 17, 0.12) 100%);
  /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(to bottom, #eeeeee 0%, #efefef 3%, #fbfbfb 16%, #fefefe 22%, white 34%, #fdfdfd 41%, #f7f7f7 50%, #e8e8e8 66%, #d5d5d5 78%, #cecece 84%, rgba(13, 15, 17, 0.44) 88%, rgba(13, 15, 17, 0.3) 94%, rgba(13, 15, 17, 0.12) 100%);
  /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
}

.full-screen-background .nav .nav-items .sxa-numbers:hover {
  color: #878787;
}

.full-screen-background .nav .nav-items .sxa-numbers.active {
  font-weight: bold;
}

.full-screen-background .nav .prev-text, .full-screen-background .nav .next-text {
  position: relative;
  font-size: 0;
  display: inline-block;
  vertical-align: middle;
  text-decoration: none;
}

.full-screen-background .nav a.next-text:hover, .full-screen-background .nav a.next-text:active {
  text-decoration: none;
  color: #878787;
}

.full-screen-background .nav a.next-text:after {
  content: "";
  display: inline-block;
  font: normal normal normal 14px/1 FontAwesome;
  font-size: inherit;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  display: block;
  font-size: 20px;
}

.full-screen-background .nav a.prev-text:hover, .full-screen-background .nav a.prev-text:active {
  text-decoration: none;
}

.full-screen-background .nav a.prev-text:after {
  display: inline-block;
  font: normal normal normal 14px/1 FontAwesome;
  font-size: inherit;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  content: "";
  display: block;
  font-size: 20px;
}

.full-screen-background.image-full-size .carousel-body {
  height: auto;
  top: 2%;
  opacity: 0.8;
}

.full-screen-background.image-full-size .carousel-link {
  left: auto;
  right: 1%;
  bottom: 2%;
  opacity: 0.8;
}

.full-screen-background .full-screen-background__container {
  height: 40vw;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  position: relative;
}

.full-screen-background .full-screen-background__container picture img {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  font-family: 'object-fit: cover;';
  object-fit: cover;
}

.full-screen-background .full-screen-background__container:before {
  content: '';
  display: block;
  position: absolute;
  width: 100%;
  left: 0;
  right: 0;
  top: 0;
  background-color: rgba(0, 0, 0, 0.5);
  height: 100%;
  z-index: 1;
}

@media (max-width: 767px) {
  .full-screen-background .full-screen-background__container {
    height: 45.5rem;
  }
}

.full-screen-background.animated-hero .full-screen-background__content {
  top: 37%;
}

.full-screen-background .youtube-background {
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  text-align: center;
  background-size: cover;
  background-position: center;
  min-height: 85vh;
  width: 100%;
  overflow: hidden;
}

@media (max-width: 1199px) {
  .full-screen-background .youtube-background {
    min-height: 72vh;
  }
}

@media (max-width: 991px) {
  .full-screen-background .youtube-background {
    min-height: 56vh;
  }
}

@media (max-width: 767px) {
  .full-screen-background .youtube-background {
    min-height: 32vh;
  }
}

.full-screen-background .youtube-background iframe {
  width: 100%;
  height: 200%;
  left: 0;
  position: absolute;
}

.full-screen-background .video-background {
  position: relative;
  height: 0;
  padding-top: 56.25%;
}

.full-screen-background .video-background iframe, .full-screen-background .video-background video {
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  object-fit: cover;
}

.full-screen-background .video-background + .full-screen-background__content {
  display: none;
}

.full-screen-background .wrapper {
  padding: 0;
}

.full-screen-background.image-full-size img {
  position: relative;
  left: 50%;
  width: auto !important;
  min-width: 100vw !important;
  max-width: none;
  min-height: 100% !important;
  transform: translateX(-50%);
}

.full-screen-background.short-header .full-screen-background__container {
  height: 20vh;
  min-height: 360px;
  margin-bottom: 0;
}

@media (max-width: 767px) {
  .full-screen-background.short-header .full-screen-background__container {
    height: 10vh;
    min-height: 160px;
  }
}

.full-screen-background.short-header .full-screen-background__content {
  top: 57%;
}

@media (max-width: 991px) {
  .home .full-screen-background .full-screen-background__container {
    margin-top: -70px !important;
    height: 100vh;
  }
}

/*
      * Main hero header element - CP
  */
.full-screen-background__container {
  position: relative;
  width: 100%;
  overflow: hidden;
  height: 40vw;
  min-height: 500px;
  max-height: 720px;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  padding: 0;
  margin-bottom: 2.4em;
  display: flex;
  align-items: center;
  justify-content: center;
}

@media (max-width: 991px) {
  .full-screen-background__container {
    flex-direction: column;
  }
}

.full-screen-background__content {
  position: absolute;
  top: 68%;
  left: 50%;
  width: 80%;
  max-width: 920px;
  transform: translate(-50%, -50%);
  color: #fff;
  z-index: 2;
}

@media (max-width: 767px) {
  .full-screen-background__content {
    top: 50%;
  }
}

.full-screen-background__content h1 {
  color: inherit;
  font-weight: 500;
  line-height: 1em;
  text-transform: uppercase;
  font-family: "Oswald", sans-serif;
  font-size: 7.2em;
}

.full-screen-background__content h1 span {
  width: 1em;
  display: block;
  height: 2px;
  background: #fff;
  margin: 0.39em auto 0.42em;
}

.full-screen-background__content p {
  margin-top: 0;
  margin-bottom: 1em;
  font-size: 2em;
  line-height: 1.25em;
}

.full-screen-background__content p:last-child {
  margin-bottom: 0;
}

.full-screen-background__content .btn.btn-link {
  color: #fff;
}

.full-screen-background__content .btn.btn-link:after {
  background-color: #fff;
}

.full-screen-background__content .btn.btn-link:hover {
  color: #fff;
}

.full-screen-background__overlayimage {
  padding: 0 170px;
  opacity: 0;
  transition: opacity 0.9s ease-in;
  width: auto;
  z-index: 2;
}

.full-screen-background__overlayimage img {
  width: 206px;
}

.full-screen-background__overlayimage.fadein {
  opacity: 0.6;
}

@media (max-width: 1469px) {
  .full-screen-background__overlayimage {
    padding: 0 100px;
  }
}

@media (max-width: 991px) {
  .full-screen-background__overlayimage {
    opacity: 0.99;
    padding: 25px;
  }
  .full-screen-background__overlayimage.fadein {
    opacity: 0.99;
  }
}

.full-screen-background__overlayimage + .full-screen-background__overlaytext:before {
  background-color: white;
  content: '';
  display: inline-block;
  height: 100px;
  left: 0;
  position: absolute;
  top: 0;
  width: 1px;
}

@media (max-width: 991px) {
  .full-screen-background__overlayimage + .full-screen-background__overlaytext:before {
    height: 1px;
    left: 50%;
    transform: translateX(-50%);
    width: 60px;
  }
}

.full-screen-background__overlaytext {
  padding: 0 170px;
  opacity: 0;
  text-align: left;
  text-transform: uppercase;
  transition: opacity 0.9s ease-in;
  position: relative;
  font-size: 2em;
  line-height: 2.5em;
  z-index: 2;
}

.full-screen-background__overlaytext h1 {
  color: white;
  font-family: "Oswald", sans-serif;
  margin-bottom: 0;
  width: 100%;
  max-width: 320px;
}

.full-screen-background__overlaytext.fadein {
  opacity: 0.3;
}

@media (max-width: 1469px) {
  .full-screen-background__overlaytext {
    padding: 0 100px;
  }
}

@media (max-width: 991px) {
  .full-screen-background__overlaytext {
    opacity: 1;
    padding: 25px;
    text-align: center;
  }
  .full-screen-background__overlaytext.fadein {
    opacity: 1;
  }
}

.nav {
  position: absolute;
  bottom: 0;
  left: 50%;
  margin-top: 0;
  transform: translateX(-50%);
}

.nav .nav-items:before, .nav .nav-items:after {
  display: none;
  content: none;
}

.nav .nav-items .sxa-bullets {
  width: 12px;
  height: 12px;
  margin-right: 12px;
  border: 2px solid #ffffff;
  border-radius: 100%;
  background: transparent;
}

.nav .nav-items .sxa-bullets.active {
  background: #FFFFFF;
}

.nav .nav-items .sxa-bullets:last-child {
  margin-right: 0;
}

.nav .nav-items .sxa-bullets:before, .nav .nav-items .sxa-bullets:after {
  display: none;
  content: none;
}

@media (max-width: 991px) {
  .full-screen-background__container {
    height: calc(100vh - 117px);
    max-height: none;
  }
}

@media (max-width: 767px) {
  .full-screen-background__container {
    min-height: 0;
  }
  .full-screen-background__content h1 {
    font-size: 6em;
  }
  .full-screen-background__content p {
    font-size: 1.8em;
  }
}

@media (max-width: 575px) {
  .full-screen-background__content h1 {
    font-size: 3.6em;
  }
  .full-screen-background__content p {
    font-size: 1.6em;
  }
}

@media (max-width: 768px) {
  .carousel {
    padding: 0;
  }
}

/*Custom Experience Editor fixes CP*/
.on-page-editor .full-screen-background__overlaytext span.scWebEditInput, .on-page-editor .full-screen-background__content span.scWebEditInput {
  width: auto;
  color: #fff;
  background: none;
}
